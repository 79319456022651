import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import "./styles.scss";
import { Button, Intent } from "@blueprintjs/core";
import {
  SelectEventArgs,
  TabComponent,
} from "@syncfusion/ej2-react-navigations";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import logo from "../assets/Medicaid-Done-Right-Logo.png";
import varialbes from "../config/variables";
import {
  AppointmentsCalendar,
  ErrorWrapper,
  FacilityScheduleView,
  Header,
  PageSidebar,
  ScheduleTabViewConfiguration,
  ScheduleViewTabConfig,
  ScheduleViews,
  User,
  UserRoles,
  featureToggleStore,
  openCreateAppointmentDialog,
  reactPluginInstance,
  userStore,
} from "@mdr-ui/core";
import { isUndefined } from "lodash";

export interface SchedulerPageComponentProps {
  tabId?: ScheduleViews;
}

const SchedulerPageComponent: React.FC<SchedulerPageComponentProps> = (
  props
) => {
  if (
    !userStore.user?.canViewScheduleMenu ||
    !featureToggleStore.featureToggles?.InAppScheduling
  ) {
    return (
      <ErrorWrapper
        error={undefined}
        resetErrorBoundary={() => {
          // do nothing
        }}
      />
    );
  }

  const navigate = useNavigate();
  const tabRef = useRef<TabComponent>(null);
  const [activeTabConfig, setActiveTabConfig] = useState(
    ScheduleViewTabConfig[ScheduleViews.AppointmentsCalendar]
  );
  const tabSelected = (args: SelectEventArgs) => {
    const defaultTab =
      ScheduleViewTabConfig[ScheduleViews.AppointmentsCalendar];

    const selectedTab = Object.values(ScheduleViewTabConfig).find(
      (config) => config.tabIndex === args.selectedIndex
    );

    setActiveTabConfig(selectedTab || defaultTab);
    navigate(
      isUndefined(selectedTab) || isUndefined(selectedTab.navigateTo)
        ? defaultTab.navigateTo
        : selectedTab.navigateTo
    );
  };
  const [schedulerLastRefreshed, setSchedulerLastRefreshed] = useState(
    new Date()
  );

  const getAvailableTabs = (user: User): ScheduleTabViewConfiguration[] => {
    const enabledTabs = Object.values(ScheduleViewTabConfig).filter(
      (x) => x.enabled
    );

    if (user?.role === UserRoles.Administrator) {
      // Administrators can view all active tabs
      return enabledTabs;
    } else if (user?.isExternal) {
      return enabledTabs.filter((x) => x.canExternalUsersView);
    } else if (
      user?.role === UserRoles.Processor ||
      user?.role === UserRoles.ProcessingManager
    ) {
      return enabledTabs.filter((x) => x.canProcessorsView);
    } else if (
      user?.role === UserRoles.FieldRepresentative ||
      user?.role === UserRoles.FieldRepresentativeManager
    ) {
      return enabledTabs.filter((x) => x.canFieldRepsView);
    }
    return []; // No tabs available if user role is not recognized
  };

  // Init Tab Selected Based on Url Path ending on facility-schedule or appointments-calendar
  useEffect(() => {
    if (props.tabId && userStore.user) {
      const tabs = getAvailableTabs(userStore.user);
      const tab = tabs.find((x) => x.key === props.tabId);
      if (tab) {
        tabRef.current?.select(tab.tabIndex);
      }
    }
  }, []);

  return (
    <div className="page scheduler-page">
      {/* Dont want page level observer */}
      <Observer>{() => <Header logo={logo} />}</Observer>
      <div className="content">
        <PageSidebar
          activePage="scheduler"
          featureToggleConfig_matchups={varialbes.featureToggleConfig.matchups}
        />
        <div className="page-main-content">
          <div className="header-bar">
            <h1>Scheduler</h1>
            {userStore.user?.canScheduleAppointment &&
              activeTabConfig ===
                ScheduleViewTabConfig[ScheduleViews.AppointmentsCalendar] && (
                <>
                  <Button
                    className="schedule-appointment-button"
                    intent={Intent.PRIMARY}
                    text="Create Appointment"
                    onClick={() => {
                      openCreateAppointmentDialog(
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        setSchedulerLastRefreshed
                      );
                    }}
                  />
                </>
              )}
          </div>
          <div className="control_wrapper" id="control_wrapper">
            <TabComponent
              ref={tabRef}
              headerPlacement="Top"
              selected={tabSelected}
              animation={{
                previous: { effect: "FadeIn" },
                next: { effect: "FadeIn" },
              }}
            >
              <div className="e-tab-header">
                {getAvailableTabs(userStore.user).map((config) => {
                  return <div key={config.key}>{config.title}</div>;
                })}
              </div>
              <div className="e-content"></div>
            </TabComponent>
            {activeTabConfig ===
              ScheduleViewTabConfig[ScheduleViews.AppointmentsCalendar] && (
              <AppointmentsCalendar
                schedulerLastRefreshed={schedulerLastRefreshed}
                setSchedulerLastRefreshed={setSchedulerLastRefreshed}
              />
            )}
            {activeTabConfig ===
              ScheduleViewTabConfig[ScheduleViews.FacilitySchedule] && (
              <FacilityScheduleView
                schedulerLastRefreshed={schedulerLastRefreshed}
                setSchedulerLastRefreshed={setSchedulerLastRefreshed}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const SchedulerPage = withAITracking(
  reactPluginInstance,
  SchedulerPageComponent,
  "SchedulerPage",
  "AI-flex-container"
);
